@font-family: 'Raleway', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
/*@font-family-cursive: 'Just Another Hand', cursive;*/

@import "utilities/palette.less";
@import "utilities/background.less";
@import "utilities/text.less";
@import "utilities/border.less";
@import "components/buttons.less";
@import "components/forms.less";
@import "components/quantity.less";
@import "components/sidebar.less";
@import "utilities/various.less";
@import "utilities/social.less";
@import "components/off-canvas.less";
@import "utilities/third-party-overrides.less";

body {
   background-color: @color-site-secondary;
   font-family: @font-family;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: @color-text;
   /*margin-top: 75px !important;

   @media (max-width: 425px) {
      margin-top: 68px !important;
   }*/
}

a, a:hover, a:focus {
   color: @color-text;
   text-decoration: none;
}

.hoverwell:hover {
   background-color: #485563;
   cursor: pointer;
}

.container {
   //TODO - maybe padding-top: 10px;
}

[hidden] {
   display: none !important;
}

/* Spinner */
.spinner-splash {
  font-size: 300%;
}
.spinner {
   font-size: 300%;
   position: absolute;
   top: 50%;
   left: 50%;
   z-index: 10
}

.fa-spinner {
   -webkit-animation: spin 1000ms infinite linear;
   animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }

   100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
   }
}

@keyframes spin {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }

   100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
   }
}
