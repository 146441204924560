@import "palette.less";
.site-text {
  color: @color-text;
}
.site-text-primary {
   color: @color-site-primary
}
.site-text-secondary {
   color: @color-site-secondary
}
.site-text-primary-training {
   color: @color-site-primary-training
}
.site-text-secondary-training {
   color: @color-site-secondary-training
}
.site-text-tertiary {
   color: @color-site-tertiary
}
.text-error {
   color: @color-error!important;
}
/*.text-fancy-cursive {
   font-family: @font-family-cursive
}*/
.text-underline {
   text-decoration: underline
}
.pointable {
   cursor: pointer;
}

.me-6, .mx-6 {
  margin-right: 3.5rem !important;
}
.me-7, .mx-7 {
  margin-right: 4rem !important;
}
.me-8, .mx-8 {
  margin-right: 4.5rem !important;
}
.me-9, .mx-9 {
  margin-right: 5rem !important;
}
.me-10, .mx-10 {
  margin-right: 5.5rem !important;
}
