@import "palette.less";
.bg-site-primary {
   background-color: @color-site-primary;
}
.bg-site-secondary {
   background-color: @color-site-secondary;
}
.bg-site-tertiary {
   background-color: @color-site-tertiary;
}
.bg-site-quaternary {
  background-color: @color-site-quaternary;
}
.bg-site-quinary {
  background-color: @color-site-quinary;
}
.bg-gray {
   background-color: #e9ecef !important;
}
