@import "palette.less";
/*toastr overrides:
  parent toastr classes are not acting right - adding !important
*/
.toast-success {
  background-color: #51A351 !important
}

.toast-error {
  background-color: #BD362F !important
}

.toast-info {
  background-color: #2F96B4 !important
}

.toast-warning {
  background-color: #F89406 !important
}

/* bootstrap overrides */
.h-95 {
   height: 95% !important;
}

.h-90 {
   height: 90% !important;
}

.card {
  border: 1px solid @color-site-quinary;
}

.card-header {
  color: @color-site-primary;
  border-bottom: 1px solid @color-site-quinary;
}

.btn-success {
  background-color: #77A163;
  border-color: #6B9358;
}

.nav-tabs .nav-link {
  font-size: 20px;
}

.list-group-item.active.light-header {
  background-color: #5389c3;
  border-color: #5389c3;

  &.success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }
}

/* for known issue with ngb-modal and stacked modals */
ngb-modal-backdrop {
   z-index: 1050 !important;
}


// ie10 + bootstrap4 overrides
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // fixes stretched images in cards
  .card-group, .card-deck {
    width: 100%;

    .card {
      display: block;
    }
  }
}


/* ngx-datatable - ViewEncapsulation.None setting causes setting class issue
   - https://stackoverflow.com/questions/47198777/ngx-datatable-cellclass-not-working
   - set classes globally
*/

.ngx-datatable {
  /* REMOVE THIS - just for designing table cells */
  .datatable-body-cell-label {
    // border: solid 1px blue;
  }

  .custom() {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
  }

  &#pending-returns-grid,
  &#info-session-grid,
  &#leads-grid {
    height: 400px;

    .datatable-body-cell.custom {
      .custom();
    }

    .name {
      //width: 170px !important;
      .material-icons {
        vertical-align: middle;
        margin-bottom: 0.2em;
      }
    }

    .phone {
      width: 136px !important;
    }

    .lastStage {
      text-align: center;
      //width: 250px !important;
    }

    .checklist {
      width: 65px !important;
      text-align: center;
    }

    .edit {
      width: 75px !important;
      text-align: center;
    }

    .infoSession,
    .greenSheet,
    .scheduled,
    .status {
      width: 110px !important;
      text-align: center;
    }

    .dateTime {
      width: 130px !important;
      text-align: center;
    }

    .common {
      width: 120px !important;
      text-align: center;
    }

    .returnSourceId {
      width: 120px !important;
    }

    .dealerDisplay {
      width: 240px !important;
    }

    .returnProducts {
      width: 450px !important;
    }

    .merchReturnForm {
      width: 300px !important;
    }

    .shortDate {
      width: 100px !important;
      text-align: center;
    }
  }
}

#pending-returns-grid.ngx-datatable .datatable-body {
  .ellipsis {font-size: 28px;line-height: 14px;}
}

.time-zone .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 180px !important;
}

/* datepicker */
.theme-green .bs-datepicker-head {
   background-color: @color-site-primary !important;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
   background-color: @color-site-primary !important;
}
.theme-green .bs-datepicker-body table td.week span {
   color: @color-site-primary !important;
}
