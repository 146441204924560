@import "../utilities/palette.less";
/* SITE-PRIMARY BUTTON */
.btn-site-primary {
   background-color: @color-site-primary;
   border-color: @color-site-primary;
   color: #FFFFFF;
}

.btn-site-primary:hover, .btn-site-primary:focus, .btn-site-primary:active, .btn-site-primary.active, .open .dropdown-toggle.btn-site-primary {
   background-color: lighten(@color-site-primary, 6%);
   border-color: lighten(@color-site-primary, 6%);
   color: #FFFFFF;
}

.btn-site-primary:active, .btn-site-primary.active, .open .dropdown-toggle.btn-site-primary {
   background-image: none;
}

.btn-site-primary.disabled, .btn-site-primary.disabled:hover, .btn-site-primary.disabled:focus, .btn-site-primary.disabled:active, .btn-site-primary.disabled.active, .btn-site-primary[disabled], .btn-site-primary[disabled]:hover, .btn-site-primary[disabled]:focus, .btn-site-primary[disabled]:active, .btn-site-primary.active[disabled], fieldset[disabled] .btn-site-primary, fieldset[disabled] .btn-site-primary:hover, fieldset[disabled] .btn-site-primary:focus, fieldset[disabled] .btn-site-primary:active, fieldset[disabled] .btn-site-primary.active {
   background-color: darken(@color-site-primary, 6%);
   border-color: darken(@color-site-primary, 6%);
}

/* GRAY BUTTON */
.btn-site-secondary {
   background-color: @color-site-secondary;
   border-color: @color-site-secondary;
   color: #FFFFFF;
}

.btn-site-secondary:hover, .btn-site-secondary:focus, .btn-site-secondary:active, .btn-site-secondary.active, .open .dropdown-toggle.btn-site-secondary {
   background-color: lighten(@color-site-secondary, 6%);
   border-color: lighten(@color-site-secondary, 6%);
   color: #FFFFFF;
}

.btn-site-secondary:active, .btn-site-secondary.active, .open .dropdown-toggle.btn-site-secondary {
   background-image: none;
}

.btn-site-secondary.disabled, .btn-site-secondary.disabled:hover, .btn-site-secondary.disabled:focus, .btn-site-secondary.disabled:active, .btn-site-secondary.disabled.active, .btn-site-secondary[disabled], .btn-site-secondary[disabled]:hover, .btn-site-secondary[disabled]:focus, .btn-site-secondary[disabled]:active, .btn-site-secondary.active[disabled], fieldset[disabled] .btn-site-secondary, fieldset[disabled] .btn-site-secondary:hover, fieldset[disabled] .btn-site-secondary:focus, fieldset[disabled] .btn-site-secondary:active, fieldset[disabled] .btn-site-secondary.active {
   background-color: darken(@color-site-secondary, 6%);
   border-color: darken(@color-site-secondary, 6%);
}

/* DARK ORANGE */
.btn-site-primary-training {
   background-color: @color-site-primary-training;
   border-color: @color-site-primary-training;
   color: white;
}

.btn-site-primary-training:hover, .btn-site-primary-training:focus, .btn-site-primary-training:active, .btn-site-primary-training.active, .open .dropdown-toggle.btn-site-primary-training {
   background-color: lighten(@color-site-primary-training, 6%);
   border-color: lighten(@color-site-primary-training, 6%);
   color: white;
}

.btn-site-primary-training:active, .btn-site-primary-training.active, .open .dropdown-toggle.btn-site-primary-training {
   background-image: none;
}

.btn-site-primary-training.disabled, .btn-site-primary-training.disabled:hover, .btn-site-primary-training.disabled:focus, .btn-site-primary-training.disabled:active, .btn-site-primary-training.disabled.active, .btn-site-primary-training[disabled], .btn-site-primary-training[disabled]:hover, .btn-site-primary-training[disabled]:focus, .btn-site-primary-training[disabled]:active, .btn-site-primary-training.active[disabled], fieldset[disabled] .btn-site-primary-training, fieldset[disabled] .btn-site-primary-training:hover, fieldset[disabled] .btn-site-primary-training:focus, fieldset[disabled] .btn-site-primary-training:active, fieldset[disabled] .btn-site-primary-training.active {
   background-color: darken(@color-site-primary-training, 6%);
   border-color: darken(@color-site-primary-training, 6%);
}

/* LIGHT ORANGE */
.btn-site-secondary-training {
   background-color: @color-site-secondary-training;
   border-color: @color-site-secondary-training;
   color: #FFFFFF;
}

.btn-site-secondary-training:hover, .btn-site-secondary-training:focus, .btn-site-secondary-training:active, .btn-site-secondary-training.active, .open .dropdown-toggle.btn-site-secondary-training {
   background-color: lighten(@color-site-secondary-training, 6%);
   border-color: lighten(@color-site-secondary-training, 6%);
   color: #FFFFFF;
}

.btn-site-secondary-training:active, .btn-site-secondary-training.active, .open .dropdown-toggle.btn-site-secondary-training {
   background-image: none;
}

.btn-site-secondary-training.disabled, .btn-site-secondary-training.disabled:hover, .btn-site-secondary-training.disabled:focus, .btn-site-secondary-training.disabled:active, .btn-site-secondary-training.disabled.active, .btn-site-secondary-training[disabled], .btn-site-secondary-training[disabled]:hover, .btn-site-secondary-training[disabled]:focus, .btn-site-secondary-training[disabled]:active, .btn-site-secondary-training.active[disabled], fieldset[disabled] .btn-site-secondary-training, fieldset[disabled] .btn-site-secondary-training:hover, fieldset[disabled] .btn-site-secondary-training:focus, fieldset[disabled] .btn-site-secondary-training:active, fieldset[disabled] .btn-site-secondary-training.active {
   background-color: darken(@color-site-secondary-training, 6%);
   border-color: darken(@color-site-secondary-training, 6%);
}

/*SITE-TERTIARY BUTTON*/
.btn-site-tertiary {
   background-color: @color-site-tertiary;
   border-color: @color-site-tertiary;
   color: #FFFFFF;
}

.btn-site-tertiary:hover, .btn-site-tertiary:focus, .btn-site-tertiary:active, .btn-site-tertiary.active, .open .dropdown-toggle.btn-site-tertiary {
   background-color: lighten(@color-site-tertiary, 6%);
   border-color: lighten(@color-site-tertiary, 6%);
   color: #FFFFFF;
}

.btn-site-tertiary:active, .btn-site-tertiary.active, .open .dropdown-toggle.btn-site-tertiary {
   background-image: none;
}

.btn-site-tertiary.disabled, .btn-site-tertiary.disabled:hover, .btn-site-tertiary.disabled:focus, .btn-site-tertiary.disabled:active, .btn-site-tertiary.disabled.active, .btn-site-tertiary[disabled], .btn-site-tertiary[disabled]:hover, .btn-site-tertiary[disabled]:focus, .btn-site-tertiary[disabled]:active, .btn-site-tertiary.active[disabled], fieldset[disabled] .btn-site-tertiary, fieldset[disabled] .btn-site-tertiary:hover, fieldset[disabled] .btn-site-tertiary:focus, fieldset[disabled] .btn-site-tertiary:active, fieldset[disabled] .btn-site-tertiary.active {
   background-color: darken(@color-site-tertiary, 6%);
   border-color: darken(@color-site-tertiary, 6%);
}

/*.btn-recruiting,
.btn-recruiting:hover,
.btn-recruiting:focus,
.btn-recruiting:active,
.btn-recruiting.active {
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#7c568f), color-stop(50%, #553A63), to(#452f50));
  background-image: -webkit-linear-gradient(#7c568f, #553A63 50%, #452f50);
  background-image: -moz-linear-gradient(top, #7c568f, #553A63 50%, #452f50);
  background-image: linear-gradient(#7c568f, #553A63 50%, #452f50);
  border: 1px solid #291d30;
  filter: none;
  color: #fff;
}*/
